@import "../../styles/variables";
@import "../../styles/mixins";

.questions {
    background-color: #0085ff;

    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        text-align: center;
        margin-bottom: 40px;
        color: #fff;
    }

    .MuiAccordionSummary-content {
        margin: 18px 0;
    }

    .MuiPaper-root {
        border-radius: 8px !important;
        margin-bottom: 16px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);

        &::before {
            display: none;
        }
    }

    .MuiAccordionDetails-root {
        padding-top: 0;
    }

    &__name {
        font-size: 20px!important;
        line-height: 28px!important;
        color: #1c1c1c;
    }

    &__answer {
        font-size: 16px;
        line-height: 24px;
        color: #585757;
    }

    @include mq("phablet") {
        &__title {
            font-size: 24px!important;
            line-height: 32px;
        }
        &__name {
            font-size: 16px!important;
            line-height: 24px!important;
        }

        &__answer {
            font-size: 13px!important;
            line-height: 20px!important;
        }
    }
}
