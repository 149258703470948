@import "../../styles/variables";
@import "../../styles/mixins";

.application {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    h3 {
        font-family: "Jost";
        color: #1c1c1c;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 13px;
    }

    h6 {
        font-size: 16px;
        color: #585757;
    }

    .MuiOutlinedInput-root {
        border-radius: 25px;
    }

    .success__modal {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 30px 50px;

        .success__modal-inner {
            .success__check {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                background: $cOne;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin: 0 auto 12px;
            }

            .reject__check {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                background: red;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin: 0 auto 12px;
            }

            .success__text {
                text-align: center;
                line-height: 32px;
                font-family: "Jost";
                font-weight: 600;
                font-size: 24px;
                color: #1c1c1c;
            }
        }
        .button_cancel {
            margin-top: 45px;
            margin-left: auto;
        }
    }

    .button_cancel {
        border: 2px solid #e8e8e8;
        width: 122px;
        color: #1c1c1c;
        margin-right: 16px;
        &:hover {
            background: #f7f7f7;
        }
    }

    .button_enter {
        width: 122px;
        background: $cOne;
        &:hover {
            background: #006acc;
        }
    }

    @include mq("phone-wide") {
        .success__modal {
            padding: 10px 20px;
        }
    }
}
