@import "../../styles/variables";
@import "../../styles/mixins";

.about {
    display: flex;
    align-items: center;

    &__work {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 24px;
    }

    &__company {
        color: #969696;
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 12px;
    }

    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
    }

    &__count {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 0;
        color: $cOne;
    }

    &__years {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 0;
        color: $cOne;
    }

    @include mq("phone-wide") {
        &__company {
            font-size: 14px;
        }

        &__title {
            font-size: 25px;
            line-height: 32px;
        }

        &__count {
            font-size: 24px;
        }
    }
}
