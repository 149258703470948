@import "../../styles/variables";
@import "../../styles/mixins";

.rate {
    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        text-align: center;
        margin-bottom: 40px;
    }

    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: dashed #e8e8e8;
        border-bottom-width: 1.5px;
        padding: 15px;

        img{
            object-fit: contain;
            width: 24px;
            height: 24px;
        }

        span {
            font-weight: 400;
            font-size: 20px;
            color: #585757;
            margin-left: 16px;
        }

        h4 {
            font-family: "Jost";
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 0;
            white-space: nowrap;
        }

        h3 {
            margin-bottom: 0;
            font-family: "Jost";
            font-weight: 600;
            font-size: 24px;
            color: #585757;
        }

        .rate__all {
            color: #1c1c1c;
        }
    }
    .rate__bottom {
        margin-top: 24px;
        margin-bottom: 0;
        font-size: 13px;
        color: #969696;
    }

    @include mq("phone-wide") {
        &__line {
            span {
                font-size: 13px;
            }

            h4 {
                font-size: 13px;
            }

            h3 {
                font-weight: 500;
                font-size: 16px;
            }
        }
    }

    @include mq("phone") {
        &__line {
            span {
                max-width: 110px;
                width: 100%;
            }
        }
    }
}
