@import "../../styles/variables";
@import "../../styles/mixins";

.smslist {
    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 38px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        color: #585757;
    }

    img {
        object-fit: contain;
        object-position: top;
        float: right;
        width: 50%;
        max-width: 100%;
        height: auto;
        margin: 0 0 30px 50px;
        border-radius: 24px;

        @include mq('phone') {
            margin: 0 0 25px 15px;
        }
    }
}
