@import "../../styles/variables";
@import "../../styles/mixins";

.aboutpage {
    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        color: #1c1c1c;
        margin-bottom: 16px;
    }

    &__description {
        color: #585757;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &__subtitle {
        font-weight: 500;
        color: #1c1c1c;
        font-size: 20px;
    }

    &__list {
        color: #585757;
        font-size: 16px;
        line-height: 24px;
    }

    &__service {
        color: #1c1c1c;
        font-size: 24px;
        font-family: "Jost";
        font-weight: 600;
        margin: 16px 0 12px;
    }

    @include mq("phablet") {
        &__title {
            font-size: 24px;
        }

        &__description {
            font-size: 13px;
            line-height: 20px;
        }

        &__subtitle {
            font-size: 16px;
        }

        &__list {
            font-size: 13px;
            line-height: 20px;
        }
    }
}
