@import "../../styles/variables";
@import "../../styles/mixins";

.intro {
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: 100vh;
    z-index: 1;
    background-color: $cOne;
    padding-bottom: 50px;
    padding-top: 100px;

    &__title {
        font-family: "Jost";
        font-weight: 600;
        color: white;
        font-size: 48px;
        margin-bottom: 12px;
    }

    &__text {
        font-weight: 400;
        color: #fff;
        font-size: 20px;
        margin-bottom: 16px;
    }

    &__circle {
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 488px;
        height: 488px;
        position: absolute;
        background-color: $cThree;
        animation: pulseCircle 5s alternate infinite;
    }

    @keyframes pulseCircle {
        from {
            transform: translate(-50%, -50%) scale(1);
        }
        from {
            transform: translate(-50%, -50%) scale(0.8);
        }
    }

    .mySwiper {
        height: 670px;
        width: 315px;
        padding: 0 34px;
        background-image: url("../../resources/img/IntroSection/Pixel.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        user-select: none;

        .swiper-wrapper {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            .swiper-slide {
                font-size: 16px;
                bottom: 25%;
                height: auto;
                visibility: hidden;
                width: 245px !important;
                left: 101%;
                color: #fff;
                max-height: 100%;

                .intro__card {
                    //transition: all .2s ease;
                    transform: scale(0);
                    padding: 10px;
                    background-color: #005099;
                    border-radius: 0 24px 24px 24px;
                }

                &-active {
                    visibility: visible;

                    .intro__card {
                        transform: scale(1);
                        transition: all .3s ease;
                    }
                }
            }
        }
    }

    @include mq("tablet-mid") {
        text-align: center;

        &__circle {
            width: 400px;
            height: 400px;
        }

        .mySwiper {
            height: 570px;
            padding: 0 45px;

            .swiper-wrapper {
                .swiper-slide {
                    left: 54%;
                    padding: 5px;
                    width: 222px !important;
                }
            }
        }

        .button_orange {
            margin: 0 auto;
        }
    }

    @include mq("phone-wide") {
        min-height: inherit;

        &__circle {
            width: 300px;
            height: 300px;
        }

        .mySwiper {
            height: 390px;
            padding: 0 80px;

            .swiper-wrapper {
                .swiper-slide {
                    font-size: 13px;
                    left: 10%;
                    padding: 5px;
                    width: 154px !important;
                }
            }
        }

        &__title {
            font-size: 28px;
        }

        &__text {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 340px) {
        .mySwiper {
            padding: 0 40px !important;
            margin: 0 auto;
            width: 230px;

            .swiper-wrapper {
                .swiper-slide {
                    left: 0%;
                    width: 150px !important;
                }
            }
        }
    }
}
