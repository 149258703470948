@import "../../styles/variables";
@import "../../styles/mixins";

.slider {
    background-color: $cOne;

    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        color: #fff;
        text-align: center;
        margin-bottom: 40px;
    }

    &__card {
        height: auto;
        padding: 50px 30px;
        background-color: #fff;
        text-align: center;
        border-radius: 16px;
        transition: all 0.3s ease;

        &__icon {
            margin-bottom: 25px;
        }

        &__text {
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #1c1c1c;
        }
    }

    .swiper-prev1 {
        cursor: pointer;
        padding: 22px;
        border-radius: 50%;
        margin-right: 20px;
        background-color: #fff !important;
        background: url("../../resources/img/SwiperSection/left.svg");
        background-repeat: no-repeat;
        background-position: center;
        transition: all .2s ease;

        &.swiper-button-disabled {
            opacity: 0.2;
        }
    }

    .swiper-next1 {
        cursor: pointer;
        padding: 22px;
        border-radius: 50%;
        margin-left: 20px;
        background-color: #fff !important;
        background: url("../../resources/img/SwiperSection/right.svg");
        background-repeat: no-repeat;
        background-position: center;
        transition: all .2s ease;

        &.swiper-button-disabled {
            opacity: 0.2;
        }
    }

    .swiper-slide-next .slider__card,
    .swiper-slide-prev .slider__card {
        transform: scale(0.8);
    }

    .swiper-pagination-lock {
        display: none;
    }

    .swiper-pagination {
        bottom: -6px !important;

        .swiper-pagination-bullet {
            background-color: #fff;
            opacity: 0.8;
        }

        .swiper-pagination-bullet-active {
            background-color: $cThree;
        }
    }

    @include mq("phablet") {
        .swiper-next1,
        .swiper-prev1 {
            display: none;
        }
        &__card {
            padding: 35px 20px;
        }
    }
}
