@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
    &__title {
        font-family: "Jost";
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        color: #1c1c1c;
        margin-bottom: 16px;
    }

    &__description {
        font-size: 16px;
        line-height: 24px;
        color: #585757;
        font-weight: 400;
    }

    &__subtitle {
        font-weight: 600;
        font-family: "Jost";
        color: #1c1c1c;
        font-size: 24px;
        line-height: 32px;
        margin-top: 24px;
    }

    &__about {
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: 28px;
        color: #585757;
        font-weight: 400;
    }

    &__map {
        position: relative;
        margin: 16px 0;

        iframe {
            border-radius: 24px;
            height: 490px;
            width: 100%;
            border: 2px solid #e8e8e8;
        }

        .map {
            & > div {
                & > a { display: none }
            }
        }

        &-block {
            pointer-events: auto;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &.show {
                pointer-events: none;
            }
        }
    }

    .MuiSvgIcon-root {
        margin-right: 15px;
    }

    @include mq("phone-wide") {
        &__title {
            font-size: 24px;
            line-height: 32px;
        }

        &__about {
            font-size: 13px;
            line-height: 24px;
        }
    }
}
