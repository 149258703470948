@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
    color: #fff;
    background: $cTwo;
    padding: 40px 0;
    margin-top: auto;

    &__title {
        font-family: "Jost";
        font-weight: 500;
        font-size: 20px;
    }

    &__subtitle {
        font-weight: 300;
        font-size: 16px;
    }

    &__info {
        font-weight: 300;
        margin-bottom: 16px;
    }

    .MuiSvgIcon-root {
        width: 20px;
        margin-right: 1rem;
    }

    &__social {
        .MuiSvgIcon-root {
            margin: 0 16px;
            width: 25px;
            height: 25px;
        }
    }
}
