@import "../../styles/variables";
@import "../../styles/mixins";

.tariffs {
    &__title {
        color: #1c1c1c;
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 20px;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #585757;
        margin-top: 20px;
    }

    &__subtitle {
        font-family: "Jost";
        font-weight: 500;
        color: #1c1c1c;
        font-size: 20px;
        line-height: 28px;
    }

    @include mq("phone-wide") {
        &__title {
            font-size: 24px;
            line-height: 32px;
        }

        &__text {
            font-size: 13px;
        }

        &__subtitle {
            font-size: 16px;
        }
    }
}
