@import "../../styles/variables";
@import "../../styles/mixins";

.document {
    h1{
        font-family: "Jost";
        font-weight: 600;
        font-size: 42px;
        color: #1c1c1c;
        margin-bottom: 12px;
    }
    p{
        color: #585757;
        font-size: 20px;
        margin-bottom: 16px;
    }

    &__image{
        width: 648px;
        max-width: 100%;
        height: auto;
    }
    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #1c1c1c;
    }

    &__text {
        color: #585757;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    &__card {
        background-color: #f7f7f7;
        padding: 20px;
        border-radius: 8px;
        margin-top: 26px;

        .document__icon {
            margin-right: 15px;
            margin-bottom: 4px;
            object-fit: contain;
            width: 40px;
            height: 40px;
        }

        .document__cardtitle {
            display: inline-block;
            margin-bottom: 0;
        }

        .document__cardtext {
            margin-top: 12px;
            margin-bottom: 0;
            color: #585757;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }

    @include mq("phone-wide") {
        h1{
            font-size: 38px;
        }
        p{
            font-size: 20px;
        }

        &__title {
            font-size: 24px;
            line-height: 32px;
        }

        &__text {
            font-size: 13px;
            line-height: 20px;
        }

        &__card {
            padding: 16px;
            margin-bottom: 12px;
        }
    }
}
