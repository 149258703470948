@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
    overflow: hidden;
    background-color: #fbfbfb;

    &__name {
        font-family: "Jost";
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 0;
    }

    &__title {
        font-family: "Jost";
        font-weight: 600;
        font-size: 36px;
        text-align: center;
        margin-bottom: 40px;
    }

    .MuiPaper-root {
        position: relative;
        margin-bottom: 20px;
        border: none;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);

        .advantage__icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            left: 16px;
            width: 64px;
            height: 64px;
            background-color: #f7f7f7;
            border-radius: 32px;
            transform: translateY(-50%);
            transition: all 0.2s ease;

            img {
                object-fit: contain;
                width: 30px;
                height: 30px;
            }
            &.advantage__orange {
                background-color: #fee7cd;
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #585757;
        }

        &::before {
            display: none;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }

    .MuiButtonBase-root {
        position: static;
        height: 104px;
        padding: 16px 16px 16px 104px;
    }

    .MuiAccordionDetails-root {
        padding: 0;
    }

    .MuiCollapse-root {
        position: relative;
        top: -16px;
        padding-left: 104px;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    @include mq("phone-wide") {
        &__name {
            font-weight: 500;
            font-size: 16px;
        }
    }
}
